<template>
    <component
        :is="component"
        v-model="menu2"
        :close-on-content-click="false"
        ref="menu"
        :return-value.sync="time"
        transition="scale-transition"
        :nudge-bottom="40"
        max-width="390px"
        min-width="390px"
      >
      <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="time"
            readonly
            v-bind="{...attrs, ...$attrs}"
            v-on="{...on, ...$listeners }"
          ></v-text-field>
        </template>
        <div class=" white grey--text text--darken-1 pa-3" v-if="$attrs.label && !breakpoint.mdAndUp">
          {{$attrs.label}}
        </div>
        <v-time-picker
            title="hello"
            v-if="menu2"
            v-model="time"
            full-width
            @click:minute="$refs.menu.save(time)"
            no-title
          ></v-time-picker>
       
      </component>
</template>

<script>
 
export default {
    props: {
      value: String
    },
    data: () => ({
      menu2: false,
    }),
    computed: {
      time: {
        get () { 
          console.log(this.value.split(':').map(n => Number(n)) );
          return this.value 
        },
        set (value) { 
          console.log(value);
          this.$emit('input', value) 
        }
      },
      component () {
        return this.breakpoint.mdAndUp ? () => import('vuetify/lib/components/VMenu') : () => import('vuetify/lib/components/VDialog')
      }
    }
 
}
</script>

<style>

</style>